import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";

gsap.registerPlugin(ScrollTrigger);

gsap.config({
  nullTargetWarn: false
})

export const initGsap = (pageName) => {


  const rsRevealAnimations = gsap.utils.toArray('.RS-animation');
  rsRevealAnimations.forEach((item) => {
    gsap.from(item, {
      scrollTrigger: {
        start: 'top bottom',
        end: 'bottom top',
        trigger: item,
        toggleClass: 'RS-animation--enter',
        once: true,
        scrub: 1,
        stagger: 5,
      }
    });
  });


  // const rsLoopedAnimations = gsap.utils.toArray('.RS-looped-animation');

  // rsLoopedAnimations.forEach(item => {
  //   gsap.from(item, {
  //     scrollTrigger: {
  //       start: 'top bottom',
  //       end: 'bottom top',
  //       trigger: item,
  //       toggleClass: 'RS-looped-animation--running',
  //       scrub: 1
  //     }
  //   });
  // });
  

  const topNavBar = document.querySelector('.RS-top-bar');

  ScrollTrigger.create({
    start: 0,
    end: 99999,
    onUpdate: ({direction}) => {
      if (direction == -1) {
        topNavBar.classList.remove('RS-top-bar--hidden');
      } else {
        topNavBar.classList.add('RS-top-bar--hidden');
      }
    }
  });


  

  if(pageName === 'Home'){

  }


};